import 'bootstrap';
import 'slick-carousel';

$(document).ready(function () {

  $('.carousel').slick({
    infinite: true,
    slidesToShow: 7,
    slidesToScroll: 1,
    prevArrow: '<a class="nav slick-prev material-icons md-24">keyboard_arrow_left</a>',
    nextArrow: '<a class="nav slick-next material-icons md-24">keyboard_arrow_right</a>',
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  });

  $('.accordion-wrapper .accordion-item .accordion-title').on('click', function(){
    $(this).closest('.accordion-item').find('.accordion-content').slideToggle();
    $(this).closest('.accordion-item').find('.accordion-title').toggleClass('active');
    $(this).closest('.accordion-item').siblings().find('.accordion-content').slideUp();
    $(this).closest('.accordion-item').siblings().find('.accordion-title').removeClass('active');
  });
});
